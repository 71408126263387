import { environment } from '@envs/environment.production'
export class ApiEndpoints {
  constructor() { }
  //Authentication

  public static login = environment.baseUrl + "/public/admin/login";
  public static loginOut = environment.baseUrl + "/public/logout";
  public static getLoggedInUser = environment.baseUrl + "/public/loggedinuser";
  public static resetPassword = environment.baseUrl + "/public/admin/reset-password/";
  public static forgotPassword = environment.baseUrl + "/public/admin/forgot-password/";

  //Company

  // public static getAllCompanies = environment.baseUrl + "/companies/?status=";
  public static getAllCompanies(status: boolean, isWhiteLabel: boolean) {
    return environment.baseUrl + `/companies/?status=${status}&whiteLabeledFlag=${isWhiteLabel}`;
  }
  public static getCompanies(pageNum: number, status: boolean) {
    return environment.baseUrl + `/companies/all/paging?pageNum=${pageNum}&status=${status}`;
  }
  public static searchCompanies(pageNum: number, searchStr: string) {
    return environment.baseUrl + `/companies/search?pageNum=${pageNum}&searchString=${searchStr}`;
  }
  public static getCompanyById = environment.baseUrl + "/companies/";
  public static deleteCompany = environment.baseUrl + "/companies/?companyId=";
  public static createCompany = environment.baseUrl + "/companies/";
  public static updateCompany = environment.baseUrl + "/companies/";
  public static updateCompanyAttributes = environment.baseUrl + "/companies/attributes";
  public static updateCompanyLogo = environment.baseUrl + "/companies/";
  public static getCompanyLogo = environment.baseUrl + "/companies/";
  public static sendRedeemCode = environment.baseUrl + "/companies/redeemCode/";
  public static getCustomerReview = environment.baseUrl + "/companies/reviews/";

  public static addPoints(companyId: number) {
    return environment.baseUrl + `/companies/${companyId}/addPoints/`;
  }

  public static getTransactions(params: { companyId: number, searchStr: string, endDate: string, startDate: string, pageNo: number }) {
    return environment.baseUrl + "/companies/" + params.companyId + "/transactions?email=" + params.searchStr + "&endDate=" + params.endDate + "&pageNum=" + params.pageNo + "&startDate=" + params.startDate + "";
  }

  public static searchTransactions(params: { companyId: number, searchStr: string, endDate: string, startDate: string, pageNo: number }) {
    return environment.baseUrl + `/companies/${params.companyId}/transactions/search?endDate=${params.endDate}&pageNum=${params.pageNo}&searchString=${params.searchStr}&startDate=${params.startDate}`;
  }

  public static getStampTransactions(params: { companyId: number, searchStr: string, endDate: string, startDate: string, pageNo: number }) {
    return environment.baseUrl + "/companies/" + params.companyId + "/stamp/transactions?email=" + params.searchStr + "&endDate=" + params.endDate + "&pageNum=" + params.pageNo + "&startDate=" + params.startDate + "";
  }

  public static searchStampTransactions(params: { companyId: number, searchStr: string, endDate: string, startDate: string, pageNo: number }) {
    return environment.baseUrl + `/companies/${params.companyId}/stamp/transactions/search?endDate=${params.endDate}&pageNum=${params.pageNo}&searchString=${params.searchStr}&startDate=${params.startDate}`;
  }

  public static redeemPoints(companyId: number) {
    return environment.baseUrl + `/companies/${companyId}/redeemPoints/`;
  }

  public static redeemStamps(companyId: number) {
    return environment.baseUrl + `/companies/stamps/${companyId}/redeemStamps`;
  }

  public static editTransaction(transactionId: number) {
    return environment.baseUrl + "/companies/transactions/" + transactionId + "";
  }

  public static editInvoiceNumber(transactionId: number) {
    return environment.baseUrl + `/companies/transactions/update/invoicenumber/${transactionId}`;
  }

  static getCustomerWalletDetails(companyId: number, customerId: number) {
    return environment.baseUrl + `/companies/${companyId}/getWallet/${customerId}`;
  }

  static addStamps(companyId: number) {
    return environment.baseUrl + `/companies/${companyId}/addStamps`;
  }

  //Admin Users

  public static getAdminUsersByStatus = environment.baseUrl + "/adminusers/status/";
  public static getAdminUsers(pageNum: number, active: boolean) {
    return environment.baseUrl + `/adminusers/adminusers/status/paging?active=${active}&pageNum=${pageNum}`;
  }
  public static searchAdminUsers(pageNum: number, searchStr: string) {
    return environment.baseUrl + `/adminusers/adminusers/search?pageNum=${pageNum}&searchString=${searchStr}`;
  }
  public static getAdminUsersByUserName = environment.baseUrl + "/adminusers/";
  public static addAdminUser = environment.baseUrl + "/adminusers/";
  public static updateAdminUser = environment.baseUrl + "/adminusers/";
  public static deleteAdminUser = environment.baseUrl + "/adminusers/";
  public static updatePassword = environment.baseUrl + "/adminusers/update-password";
  public static getCompanyTransactions(params: { endDate: string, startDate: string, pageNum: number }) {
    return environment.baseUrl + `/adminusers/transactions/paging/?endDate=${params.endDate}&pageNum=${params.pageNum}&startDate=${params.startDate}`;
  }
  public static searchCompanyTransactions(params: { endDate: string, startDate: string, pageNum: number, searchStr: string }) {
    return environment.baseUrl + `/adminusers/transactions/search?endDate=${params.endDate}&pageNum=${params.pageNum}&searchString=${params.searchStr}&startDate=${params.startDate}`;
  }
  public static getAppUsers(params: { endDate: string, startDate: string, pageNum: number }) {
    return environment.baseUrl + `/adminusers/dashboard/registrations/datewise/users?endDate=${params.endDate}&pageNum=${params.pageNum}&startDate=${params.startDate}`;
  }
  public static searchAppUsers(params: { endDate: string, startDate: string, pageNum: number, searchStr: string }) {
    return environment.baseUrl + `/adminusers/appusers/datewise/search?endDate=${params.endDate}&pageNum=${params.pageNum}&searchString=${params.searchStr}&startDate=${params.startDate}`;
  }
  public static getAppUsersCount(endDate: string, startDate: string) {
    return environment.baseUrl + `/adminusers/dashboard/registrations/datewise/?endDate=${endDate}&startDate=${startDate}`;
  }

  public static getTransactionReport(startDate: string, endDate: string) {
    return environment.baseUrl + `/adminusers/transactions/summary?endDate=${endDate}&startDate=${startDate}`;
  }

  public static deleteAppUser = environment.baseUrl + "/adminusers/deleteappuser/";
  //Offers

  public static getOffers(companyId: number, active: number, page: number) {
    return environment.baseUrl + "/companies/" + companyId + "/offers?active=" + active + "&pageNum=" + page + "";
  }

  public static getOfferById(companyId: number, offerId: number) {
    return environment.baseUrl + "/companies/" + companyId + "/offers/" + offerId + "";
  }

  public static createOffer(companyId: number) {
    return environment.baseUrl + "/companies/" + companyId + "/offers";
  }

  public static additionalOfferDetails(companyId: number, offerId: number) {
    return environment.baseUrl + "/companies/" + companyId + "/offers/" + offerId + "/additional-details";
  }

  public static updateOffer(companyId: number, offerId: number) {
    return environment.baseUrl + "/companies/" + companyId + "/offers/" + offerId + "";
  }

  public static deleteOffer(companyId: number, offerId: number) {
    return environment.baseUrl + "/companies/" + companyId + "/offers/" + offerId + "";
  }

  static updateOrGetOfferImage(companyId: number, offerId: number) {
    return environment.baseUrl + "/companies/" + companyId + "/offers/" + offerId + "/image";
  }

  //Stamp Card

  static getStampCardDefinitionByCompanyId(companyId: number) {
    return environment.baseUrl + `/stamp/definition/?companyId=${companyId}`;
  }

  static createStampCardDefinition() {
    return environment.baseUrl + `/stamp/definition/`;
  }

  static updateStampCardDefinition(id: number) {
    return environment.baseUrl + `/stamp/definition/${id}`;
  }

  //Customers / App Users

  public static getActiveCustomers(companyId: number, status: boolean) {
    return environment.baseUrl + "/appusers/?companyId=" + companyId + "&status=" + status + "";
  }

  public static getCustomersDetails = environment.baseUrl + "/appusers/username/";

  public static addOrUpdateCustomer = environment.baseUrl + "/appusers/?companyId=";

  public static deleteCustomer(username: string, companyId: number) {
    return environment.baseUrl + "/appusers/" + username + "?companyId=" + companyId + "";
  }

  //Wallet

  public static saveWalletSettings(companyId: number) {
    return environment.baseUrl + "/companies/" + companyId + "/walletsettings";
  }

  public static getWalletSettings(companyId: number, status: boolean) {
    return environment.baseUrl + "/companies/" + companyId + "/walletsettings?status=" + status + "";
  }

  //Notification

  public static sendNotification = environment.baseUrl + `/notification/push/notification/?email=`;

}
